<template>
<loader v-bind="{ loading }">
  <form>
    <columns>
      <column>
        <text-input
          classes="is-medium is-rounded"
          :error="$root.errors.name"
          :value="branch.name"
          @input="updateName">Name</text-input>
      </column>
      <column>
        <data-selector
          classes="is-medium is-rounded"
          searchable
          :items="user_list"
          value-key="uuid"
          label-key="full_name"
          :error="$root.errors.branch_manager_uuid"
          :value="branch.branch_manager_uuid"
          @input="setBranchManager">Branch Manager</data-selector>
      </column>
    </columns>
    <columns>
      <column>
        <data-selector 
          classes="is-medium is-rounded" 
          tag-class="is-medium is-rounded"
          delete-button-class="is-medium is-rounded is-danger"
          multiple 
          searchable 
          :items="user_list" 
          value-key="id"
          label-key="full_name" 
          :error="$root.errors.users" 
          :value="branch.users"
          @input="updateUsers">
          Staff
        </data-selector>
      </column>
    </columns>
    <columns>
      <column>
        <submit-button
          class="is-medium is-rounded"
          :working="updating"
          @submit="updateBranch">Save</submit-button>
      </column>
    </columns>
  </form>
</loader>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'
import findIndex from 'lodash/findIndex'

export default {

  data: () => ({
    loading: true,
    updating: false,
  }),

  async beforeCreate() {
    await this.$store.dispatch('user/loadList')
    this.loading = false
  },

  methods: {
    ...mapMutations('branch', [
      'updateName',
      'updateUsers',
      'updateBranchManager'
    ]),
    setBranchManager(userId) {
      if(findIndex(this.branch.users, user => user.uuid === userId) === -1) {
        this.updateUsers(this.branch.users.push(this.user_list.find(user => user.uuid === userId)))
      }
      this.updateBranchManager(userId)
    },
    updateBranch() {
      this.updating = true
      this.$store.dispatch('branch/update').then(() => {
        this.$toast.success('Branch successfully updated')
        this.updating = false
      }).catch(() => {
        this.updating = false
      })
    }
  },

  computed: {
    ...mapGetters('branch', [
      'branch'
    ]),
    ...mapGetters('user', [
      'user_list'
    ])
  }

}
</script>